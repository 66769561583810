import DashboardIcon from "@mui/icons-material/Dashboard";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const SideBarContent = [
  {
    key: "1",
    path: "/dashboard",
    label: "Dashboard",
    icon: <DashboardIcon />,
    roles: ["admin"],
  },
  {
    key: "2",
    path: "/dashboard/tunes",
    label: "Tunes",
    icon: <LibraryMusicIcon />,
    roles: ["admin"],
  },
  {
    key: "6",
    label: "Users",
    icon: <GroupIcon />,
    roles: ["admin"],
    path: "/dashboard/create_accounts",
  },
  {
    key: "3",
    label: "Subscriptions",
    icon: <GroupAddIcon />,
    roles: ["all"],
    routes: [
      {
        key: "4",
        path: "/dashboard/subscriptions_per_day",
        label: "Per Day",
        roles: ["all"],
      },
      {
        key: "5",
        path: "/dashboard/subscriptions_per_channel",
        label: "Per Channel",
        roles: ["admin"],
      },
      {
        key: "14",
        path: "/dashboard/subscriptions_per_hour",
        label: "Per Hour",
        roles: ["admin"],
      },
    ],
  },
  {
    key: "7",
    label: "Recruitments",
    icon: <DashboardIcon />,
    roles: ["admin"],
    routes: [
      {
        key: "8",
        path: "/dashboard/daily_recruitments",
        label: "Per day",
        roles: ["admin"],
      },
      {
        key: "9",
        path: "/dashboard/recruitments_per_date",
        label: "Per date",
        roles: ["admin"],
      },
      {
        key: "13",
        path: "/dashboard/recruitments_per_hour",
        label: "Per hour",
        roles: ["admin"],
      },
    ],
  },
  {
    key: "10",
    label: "Sms Logs",
    icon: <DashboardIcon />,
    roles: ["admin"],
    routes: [
      {
        key: "11",
        path: "/dashboard/daily_sms_logs",
        label: "Per day",
        roles: ["admin"],
      },
      {
        key: "12",
        path: "/dashboard/sms_logs_per_date",
        label: "Per date",
        roles: ["admin"],
      },
    ],
  },
];

export default SideBarContent;
