import { type PropsWithChildren, useState } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import getThemeByName from ".";
import { getThemeName } from "../utils/helpers";

function MyThemeProvider({ children }: PropsWithChildren): JSX.Element {
  const [themeName] = useState<string>(getThemeName());
  const theme = getThemeByName(themeName ?? "defaultDark");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default MyThemeProvider;
