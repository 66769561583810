import { createBrowserRouter } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import { Suspense, lazy } from "react";
import AuthGuard from "./AuthGuard";
import Dashboard from "../pages/dashboard/Dashboard";
import Layout from "../layout/Layout";
import Tunes from "../pages/tunes/Tunes";
import AddTune from "../pages/tunes/addTune";
import SubsPerDay from "../pages/subscriptions/SubsPerDay";
import SubsPerChannel from "../pages/subscriptions/SubsPerChannel";
import CreateAccount from "../pages/authentication/CreateAccount";
import Recruitment from "../pages/Recruitment/Recruitment";
import RecruitmentsPerDate from "../pages/Recruitment/RecruitmentsPerDate";
import SmsLogs from "../pages/SmsLogs/SmsLogs";
import SmsLogsPerDate from "../pages/SmsLogs/SmsLogsPerDate";
import RecruitmentsPerHour from "../pages/Recruitment/RecruitmentsPerHour";
import SubsPerHour from "../pages/subscriptions/SubscriptionsPerHour";

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<PageLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(lazy(() => import("../pages/authentication/Login")));

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <Login />
      </AuthGuard>
    ),
  },
  {
    path: "/login",
    element: (
      <AuthGuard>
        <Login />
      </AuthGuard>
    ),
  },

  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/tunes",
        element: <Tunes />,
      },
      {
        path: "/dashboard/add-tunes",
        element: <AddTune />,
      },
      {
        path: "/dashboard/subscriptions_per_day",
        element: <SubsPerDay />,
      },
      {
        path: "/dashboard/subscriptions_per_channel",
        element: <SubsPerChannel />,
      },
      {
        path: "/dashboard/create_accounts",
        element: <CreateAccount />,
      },
      {
        path: "/dashboard/daily_recruitments",
        element: <Recruitment />,
      },
      {
        path: "/dashboard/recruitments_per_date",
        element: <RecruitmentsPerDate />,
      },
      {
        path: "/dashboard/daily_sms_logs",
        element: <SmsLogs />,
      },
      {
        path: "/dashboard/sms_logs_per_date",
        element: <SmsLogsPerDate />,
      },
      {
        path: "/dashboard/recruitments_per_hour",
        element: <RecruitmentsPerHour />,
      },
      {
        path: "/dashboard/subscriptions_per_hour",
        element: <SubsPerHour />,
      },
    ],
  },
]);

export default Router;
