import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import Login from "../pages/authentication/Login";

const AuthGuard = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth);

  const [requestedLocation, setRequestedLocation] = useState<string>(
    location.pathname || "/dashboard"
  );

  useEffect((): any => {
    if (auth.isAuthenticated === false) {
      navigate("/login");
    } else {
      // if (auth.user.role[0] === "presenter") {
      //   navigate("/dashboard/subscriptions_per_day");
      // } else {
      //   navigate(requestedLocation);
      // }
      navigate(requestedLocation);
    }
  }, [auth]);

  return <div>{children}</div>;
};

export default AuthGuard;
