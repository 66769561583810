import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import skizaApi from "../../api/skizaApi";
import axiosInstance from "../../api/base/axios";
import { DataGrid, type GridColDef } from "@mui/x-data-grid";
import AddTune from "./addTune";
import { useNavigate } from "react-router-dom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../hooks/fireBaseConfig";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { skizaTunes } from "../../redux/tunesSlice";

interface ITunes {
  id?: string;
  name?: string;
  code?: string;
  enabled?: boolean;
  date_created?: string;
}

const Tunes = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.tunes.skizaTunes);

  const [loading, setLoading] = useState<boolean>(false);
  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [fetch, setFetch] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [tunes, setTunes] = useState<ITunes[]>(selector);
  const [tune, setTune] = useState<ITunes>();

  const handleModal = () => {
    setOpen(!open);
  };

  const fetchTunes = async (): Promise<any> => {
    try {
      setLoading(true);
      await axiosInstance.get("/skiza/tunes").then((res) => {
        setTunes(res.data.tunes);
        setLoading(false);
        dispatch(skizaTunes(res.data.tunes));
      });
    } catch (error) {
      setLoading(false);
      console.log("err", error);
    }
  };

  useEffect(() => {
    if (fetch === 0 && tunes.length <= 0) {
      fetchTunes();
      setFetch(1);
    }
  }, [fetch]);

  const columns: Array<GridColDef<ITunes>> = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Skiza name",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Skiza code",
      flex: 1,
    },
    {
      field: "enabled",
      headerName: "enabled",
      flex: 1,
    },
    {
      field: "date_created",
      headerName: "Date created",
      flex: 1,
    },
    {
      field: "",
      headerName: "",
      renderCell: (row: any) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleModal();
            setTune(row.row);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const deleteTune = async (): Promise<any> => {
    try {
      setDelLoading(true);
      await axiosInstance
        .delete(`/skiza/tunes/${tune?.id}/delete`)
        .then(async (res) => {
          setFetch(0);
          setDelLoading(false);
          handleModal();
          enqueueSnackbar("Tune deleted successfully!", {
            variant: "success",
          });

          let colRef = collection(db, "skiza_tunes");

          let docRef = query(colRef, where("skiza_code", "==", tune?.code));

          getDocs(docRef).then((querySnapshot) =>
            querySnapshot.forEach((docu) => {
              deleteDoc(doc(db, "skiza_tunes", docu.id))
                .then(() => {
                  enqueueSnackbar("Tune deleted successfully fro firebase!", {
                    variant: "success",
                  });
                })
                .catch((error) => {
                  console.error("Error deleting document: ", error);
                  enqueueSnackbar("Error deleting document: " + error, {
                    variant: "error",
                  });
                });
            })
          );
        });
    } catch (error: any) {
      setDelLoading(false);
      enqueueSnackbar(error.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Delete Tune
          </Typography>
        </DialogTitle>

        <DialogContent
          sx={{
            width: "400px",
          }}
        >
          <Grid container display={"flex"} justifyContent={"center"}>
            <Grid item>
              <ReportProblemIcon color="warning" sx={{ fontSize: "200px" }} />
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 600 }} align="center">
                Are you sure you want to delete.
              </Typography>
              <Typography sx={{ fontWeight: 600 }} align="center">
                Skiza code: {tune?.code}
              </Typography>
              <Typography sx={{ fontWeight: 600 }} align="center">
                Skiza name: {tune?.name}
              </Typography>
            </Grid>

            <Grid item sx={{ mt: "20px" }}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                spacing={16}
              >
                <Button variant="contained" color="error" onClick={handleModal}>
                  cancel
                </Button>
                <LoadingButton variant="contained" onClick={deleteTune}>
                  Confirm
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={4}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item flex={1}>
                  <Typography variant="h4">All Tunes</Typography>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/dashboard/add-tunes")}
                  >
                    Add tune
                  </Button>
                </Grid>

                <Grid item xs={12} sx={{ height: "calc(100vh - 180px)" }}>
                  <DataGrid
                    initialState={{
                      sorting: { sortModel: [{ field: "id", sort: "desc" }] },
                    }}
                    loading={loading}
                    columns={columns}
                    rows={tunes}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Tunes;
