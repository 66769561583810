import { createTheme, darken, lighten } from "@mui/material/styles";

const PRIMARY_COLOR = "#2B4067";
const SECONDARY_COLOR = "#CC388A";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  custom: {
    sideNav: lighten(PRIMARY_COLOR, 0.9),
    sideNavRadius: 6,
    appBar: lighten(PRIMARY_COLOR, 0.9),
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingBottom: 16,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: darken(PRIMARY_COLOR, 0.2),
          textDecorationColor: darken(PRIMARY_COLOR, 0.2),
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
      defaultProps: { elevation: 2 },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
  },
});

export default defaultTheme;
