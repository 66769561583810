import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { db, storage } from "../../hooks/fireBaseConfig";
import { addDoc, collection } from "firebase/firestore";
import axiosInstance from "../../api/base/axios";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

interface IFormInput {
  name: string;
  code: number;
  audio: any;
}

const AddTune = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [audio, setAudio] = useState<any>();
  const [category, setCategory] = useState<any>();

  const onSubmit: SubmitHandler<IFormInput> = async (e): Promise<any> => {
    try {
      setLoading(true);
      const storageRef = ref(storage, "skiza_tunes/" + audio?.name);

      const uploadTask = uploadBytesResumable(storageRef, audio);

      await axiosInstance
        .post("/skiza/tunes/add", {
          code: e.code,
          name: e.name,
        })
        .then(() => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              console.log("error", error);
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                // after getting audio url add it to database
                addDoc(collection(db, "skiza_tunes"), {
                  name: e.name,
                  skiza_code: e.code,
                  audio_url: downloadURL,
                  available: true,
                  category: category,
                })
                  .then(() => {
                    enqueueSnackbar("Tune added successfully!", {
                      variant: "success",
                    });
                    setTimeout(() => {
                      navigate(-1);
                    }, 2000);
                    setLoading(false);
                  })
                  .catch((error) => {
                    setLoading(false);
                    enqueueSnackbar(error.response.data.message, {
                      variant: "error",
                    });
                  });
              });
            }
          );
        });
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Add Tune Form
          </Typography>
        }
      />
      <CardContent
        sx={{
          width: "500px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            fullWidth
            error={!!errors.name}
            sx={{
              mt: "20px",
            }}
          >
            <InputLabel htmlFor="name">Skiza name</InputLabel>
            <OutlinedInput
              id="name"
              type="text"
              {...register("name", { required: true })}
              label="Skiza name"
            />
            {errors.name && (
              <FormHelperText>Please enter an name!</FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={!!errors.code}
            sx={{
              mt: "20px",
            }}
          >
            <InputLabel htmlFor="code">Skiza Code</InputLabel>
            <OutlinedInput
              id="code"
              type="number"
              {...register("code", { required: true })}
              label="Skiza Code"
            />
            {errors.code && (
              <FormHelperText>Please enter an code!</FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              mt: "20px",
            }}
          >
            <InputLabel id="category">Category</InputLabel>
            <Select
              labelId="category"
              id="category"
              defaultValue=""
              value={category}
              label="Category"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <MenuItem value={"skiza_tune"}>Skiza tune </MenuItem>
              <MenuItem value={"skiza_biashara"}>Skiza biashara </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            error={!!errors.code}
            sx={{
              mt: "20px",
            }}
          >
            <OutlinedInput
              id="audio"
              type="file"
              inputProps={{
                accept: "audio/mpeg, audio/mp4, audio/ogg, audio/wav",
              }}
              {...register("audio", { required: true })}
              label="Skiza Code"
              onChange={(e: React.ChangeEvent<HTMLInputElement | any>) => {
                setAudio(e.target.files[0]);
              }}
            />
            {errors.audio && (
              <FormHelperText>Please enter an audio!</FormHelperText>
            )}
          </FormControl>

          <Box
            sx={{
              mt: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="reset" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Add
            </LoadingButton>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddTune;
