import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionDay: [],
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    subscriptionDay: (state, action) => {
      state.subscriptionDay = action.payload;
    },
  },
});

export const { subscriptionDay } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
