import { createSlice } from "@reduxjs/toolkit";
import {
  getUserDetails,
  setToken,
  setUserDetails,
  logout as logoutUser,
  getToken,
} from "../utils/helpers";

const initialState = {
  user: getUserDetails() as any | null,
  isAuthenticated: getUserDetails() === undefined ? false : true,
  token: getToken(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      setUserDetails(action.payload);
      setToken(action.payload.session_id);
    },
    logout: (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      logoutUser();
      // localStorage.clear();
      // window.location.reload();
    },
    loggedInUser: (state, actions) => {
      if (initialState.token) {
        state.user = initialState.user;
        state.isAuthenticated = true;
      } else {
        state.user = null;
        state.isAuthenticated = false;
      }
    },
  },
});

export const { login, logout, loggedInUser } = authSlice.actions;

export default authSlice.reducer;
