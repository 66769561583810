import { createTheme, darken } from "@mui/material/styles";
import { grey, red } from "@mui/material/colors";

const PRIMARY_COLOR = red[700];
const SECONDARY_COLOR = grey.A200;

const defaultDarkTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    mode: "dark",
  },
  custom: {
    sideNav: darken(PRIMARY_COLOR, 0.95),
    appBar: darken(PRIMARY_COLOR, 0.95),
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingBottom: 16,
        },
      },
    },
  },
});

export default defaultDarkTheme;
