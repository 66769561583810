import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import skizaApi from "../../api/skizaApi";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

interface IFormInput {
  msisdn: string;
  username: string;
}

const CreateAccount = (): JSX.Element => {
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormInput>();

  const [role, setRole] = useState<string>("");

  const onSubmit: SubmitHandler<IFormInput> = async (e): Promise<any> => {
    try {
      let formData = {
        msisdn: e.msisdn.replaceAll("+", ""),
        username: e.username,
        role_id: [role],
      };

      await skizaApi.auth.registerUser(formData).then((res) => {
        enqueueSnackbar("User account created!", { variant: "success" });
        reset();
      });
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: "error" });
      reset();
    }
  };

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">Create User account</Typography>}
      />

      <CardContent sx={{ width: "500px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="msisdn"
                control={control}
                render={({ field, fieldState: { error: e } }) => (
                  <MuiTelInput
                    {...field}
                    fullWidth
                    disableFormatting
                    autoComplete="phone-no"
                    defaultCountry="KE"
                    onlyCountries={["KE"]}
                    helperText={e != null ? "Enter valid phone number" : ""}
                    error={Boolean(errors?.msisdn)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.username}>
                <InputLabel htmlFor="username">Username</InputLabel>
                <OutlinedInput
                  id="username"
                  label="Username"
                  {...register("username", { required: true })}
                />
                {errors.username && (
                  <FormHelperText>Enter your username</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                sx={{
                  mt: "20px",
                }}
              >
                <InputLabel id="role">Role</InputLabel>
                <Select
                  labelId="role"
                  id="role"
                  defaultValue=""
                  value={role}
                  label="Role"
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  <MenuItem value={"1"}>Admin</MenuItem>
                  <MenuItem value={"2"}>Presenter</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="reset" onClick={() => reset()}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Create
            </LoadingButton>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateAccount;
