import axios from "axios";
import { getToken } from "../../utils/helpers";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "https://skiza.mobiapps.co.ke"
    : "https://skiza.mobiapps.co.ke";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "session-id": getToken(),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
});

// axiosInstance.defaults.headers.common["session-id"] = getToken();

axiosInstance.interceptors.request.use(function (config) {
  config.headers["session-id"] = getToken();
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Headers"] =
    "Origin, X-Requested-With, Content-Type, Accept";
  return config;
});

export default axiosInstance;
