import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import skizaApi from "../../api/skizaApi";
import axiosInstance from "../../api/base/axios";
import { DataGrid, type GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

interface ITunes {
  date: string;
  count: string;
}

const SubsPerDay = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [tunes, setTunes] = useState<ITunes[]>([]);

  const handleModal = () => {
    setOpen(!open);
  };

  const fetchTunes = async (): Promise<any> => {
    try {
      setLoading(true);
      await axiosInstance.get("/skiza/report/date").then((res) => {
        setTunes(res.data.subscriptions);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log("err", error);
    }
  };

  useEffect(() => {
    fetchTunes();
  }, []);

  const columns: Array<GridColDef<ITunes>> = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
    },
  ];

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <Container maxWidth={false}>
      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Add Tune Form
          </Typography>
        </DialogTitle>

        <DialogContent
          sx={{
            width: "400px",
          }}
        >
          {/* <AddTune /> */}
        </DialogContent>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={4}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item flex={1}>
                  <Typography variant="h4">Subscriptions per Day</Typography>
                </Grid>

                <Grid item>
                  {/* <Button
                    variant="contained"
                    onClick={() => navigate("/dashboard/add-tunes")}
                  >
                    Add tune
                  </Button> */}
                </Grid>

                <Grid item xs={12} sx={{ height: "calc(100vh - 180px)" }}>
                  <DataGrid
                    getRowId={(row) => generateRandom()}
                    initialState={{
                      sorting: { sortModel: [{ field: "date", sort: "desc" }] },
                    }}
                    loading={loading}
                    columns={columns}
                    rows={tunes}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubsPerDay;
