import { get, post } from "./base";

const skizaApi = {
  auth: {
    login: (data: any) => post("/skiza/login", data),
    forgotPassword: (data: any) => post("/skiza/reset_password", data),
    registerUser: (data: any) => post("/skiza/add_user", data),
  },
  tunes: {
    fetchAll: () => get("/skiza/tunes"),
  },
};

export default skizaApi;
