import { AppBar, IconButton, Toolbar, useTheme } from "@mui/material";
import React, { useState } from "react";
import SideNav from "./SideNav";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { Navigate, Outlet } from "react-router-dom";
// import Avatar from "./components/Avatar";
import { getToken } from "../utils/helpers";

const drawerWidth = 300;
const toolbarHeight = 70;

const Layout = () => {
  const theme = useTheme();
  const isMobile = window.screen.width < 992;
  const isLoggedIn = getToken();

  const [open, setOpen] = useState(!isMobile);

  const MainContent = styled("main")(({ theme }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.background.paper,
    marginTop: `${toolbarHeight}px`,
    ...(!isMobile &&
      open && {
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }),
  }));

  return (
    <>
      <>
        <AppBar
          sx={{
            backgroundColor: theme.palette.background.paper,
            transition: theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            ...(!isMobile &&
              open && {
                width: `calc(100% - ${drawerWidth}px)`,
                paddingingLeft: `${drawerWidth}px`,
                transition: theme.transitions.create(["margin", "width"], {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }),
          }}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={() => setOpen(true)}
              sx={{
                mr: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton aria-label="home">
              <img src="/Logo.svg" alt="logo" width={100} height={50} />
            </IconButton>
            {/* <Avatar /> */}
          </Toolbar>
        </AppBar>

        <SideNav
          open={open}
          isMobile={isMobile}
          drawerWidth={drawerWidth}
          onToggleNav={() => setOpen(!open)}
        />

        <MainContent>
          <Outlet />
        </MainContent>
      </>
    </>
  );
};

export default Layout;
