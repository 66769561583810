import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  skizaTunes: [],
};

const tuneSlice = createSlice({
  name: "tunes",
  initialState,
  reducers: {
    skizaTunes: (state, action) => {
      state.skizaTunes = action.payload;
    },
  },
});

export const { skizaTunes } = tuneSlice.actions;

export default tuneSlice.reducer;
